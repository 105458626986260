import { Routes } from "@angular/router";
import { AppComponent } from "../app/app.component"
import { WeightComponent } from "./weight/weight.component";


export const rootRouterConfig: Routes = [
  {
    path: "",
    component: WeightComponent,
    children: [
    
      {
        path: 'weight',
        loadChildren: () => import('./weight/weight.module').then(m => m.WeightModule),
        data: { title: "Weight", breadcrumb: "Weight" }
      },
    ]
  },

];