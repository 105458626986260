
export const environment = {
  production: false,
  // apiURL: "https://api.sparkfitness.fit/api/V1/GP/",
  apiURL: "https://api.zymmechanix.com/api/V1/GP/",
  image:'https://api.zymmechanix.com/member/',
  mqttConfig: {
    hostname: "device.zymmechanix.com",
    port: 8084,
    path: "/mqtt",
    protocol: "wss",
    username : "mechnix"  ,
    password : "Mech#2023"
  }
};
// https://api.zymmechanix.com/avatar.jpg