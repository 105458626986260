import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexChart,
  ApexFill,
  ChartComponent,
  ApexResponsive
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  responsive: ApexResponsive;
};
import {
  format,
} from "date-fns";
import { ActivatedRoute } from '@angular/router';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { interval, Subscription } from 'rxjs';
import { AppLoaderService } from '../services/app-loader.service';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-weight',
  templateUrl: './weight.component.html',
  styleUrls: ['./weight.component.css'],
})
export class WeightComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  @ViewChild('bmichart') bmichart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public bmichartOptions: Partial<ChartOptions>;
  now: string;
  Time: string
  title = 'weight-monitor';
  topicname: string;
  private subscription: Subscription;
  msg: any;
  mySubscription: Subscription;
  todayDate: Date;
  branchId: any;
  details: any;
  updatetime: any
  profileImg = 'assets/images/avartar.jpg';
  
  constructor(
    private _mqttService: MqttService,
    private loader: AppLoaderService,
    private activateRoute: ActivatedRoute
  ) {
    this.branchId = this.activateRoute.snapshot.params['branchId'] ?? '';
    this.todayDate = new Date();
    this.Time = format(new Date(), " h:mm a")
    this.setTimeInterval();

    // console.log(
    //   JSON.stringify({
    //     memberId: 4,
    //     memberName: 'Suriya Prakash',
    //     memberImage: 'member-1619509003-SGlsi7cVbO.png',
    //     memberWeight: 12.54,
    //     memberAge: 22,
    //     deviceMac: '158192128174',
    //     dateTime: '2021-05-06 07:58:57',
    //   })
    // );
  }
  weightChartOptions: {
    title: {
      text: 'Weight Measurement',
    },
    tooltip: {}; xAxis: { data: string[]; showGrid: boolean; splitLine: { show: boolean; }; }; yAxis: { type: string; splitLine: { show: boolean; }; }; series: { name: string; type: string; data: number[]; showAllSymbol: boolean; symbol: string; symbolSize: number; itemStyle: { color: string; }; lineStyle: { normal: { color: string; }; }; }[];
  };
  ngOnInit() {
    this.getChart();
    this.getbmiChart();
    this.subscribeNewTopic();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setTimeInterval() {
    setInterval(() => {
      this.now = format(new Date(), " hh:mm:ss a");
    }, 1);
  }
  imageerror(){
    this.profileImg = '../../assets/user-circle.png'
  }
  subscribeNewTopic(): void {
    this.subscription?.unsubscribe();
    var topicName = `FRONT/MECHNIX/1/1/WEIGHT/POST`//`FRONT/SPARK/${this.branchId}/1/WEIGHT/POST`;
    let timeout;
    this.subscription = this._mqttService
      .observe(topicName)
      .subscribe((message: IMqttMessage) => {
        clearTimeout(timeout);
        this.details =
          message?.payload?.toString() == 'Data'
            ? ''
            : JSON?.parse(message?.payload?.toString());
        console.log('weight', this.details)
        this.getChart();
      
          // this.profileImg = 'https://api.petalyellow.com/public/member/' + this.details?.memberImage;
          this.profileImg = `${environment.image}${this.details?.memberImage}`;
        

        if (this.details?.dateTime - this.updatetime <= 3) {
          this.getChart();
          this.getbmiChart();
        } else {
        }
        if (this.updatetime !== this.details?.dateTime) {
          if (this.details?.memberWeight) {
            this.getChart();
            this.getbmiChart();
          }
        }
        else {
        }
        this.updatetime = this.details?.dateTime;
        timeout = setTimeout(() => {
          this.details = null;
        }, 10000);
      });
    console.log('subscribed to topic: ' + topicName);
  }
  $unit = 'Kg';

  
  getChart() {
    const currentvalue = (this.details?.memberWeight) ? this.details?.memberWeight : 0;
    this.chartOptions = {
      series: [currentvalue],
      chart: {
        type: 'radialBar',
        offsetY: -20,
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: {
            margin: 15,
            size: '85%',
          },
          dataLabels: {
            name: {
              show: true,
              color: '#DADADA',
              fontSize: '22px',
              fontFamily: 'Trebuchet MS',
              offsetY: 70,
            },
            value: {
              color: 'white',
              fontSize: '100px',
              fontFamily: 'Trebuchet MS',
              show: true,
              formatter: function (val) {
                return val + ``;
              },
            },
          },

          // track: {
          //   background: 'red',
          //   strokeWidth: '100%',
          //   // margin: 5, // margin is in pixels
          //   dropShadow: {
          //     enabled: true,
          //     top: 2,
          //     left: 0,
          //     opacity: 0.31,
          //     blur: 2,
          //   },
          // },
        },
      },
      fill: {
        type: 'gradient',
        colors: ['#00ce76'],
        // gradient: {
        //   shade: 'light',
        //   shadeIntensity: 0.4,
        //   inverseColors: false,
        //   opacityFrom: 1,
        //   opacityTo: 1,
        //   stops: [0, 50, 53, 91],
        // },
      },
      labels: ['Weight(kg)'],
    };
  }


  getbmiChart() {
    const currentvalue = (this.details?.bmi) ? this.details?.bmi : 0;
    const maxValue = 44;
    const minValue = 0;
    let precentage = (Number(currentvalue - Number(minValue)) * 100) / (Number(maxValue) - Number(minValue));
    this.bmichartOptions = {
      series: [precentage],
      chart: {
        type: 'radialBar',
        offsetY: -20,
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: {
            margin: 15,
            size: '85%',
          },
          // [startValue, endValue]
          dataLabels: {
            name: {
              show: true,
              color: '#DADADA',
              fontSize: '22px',
              fontFamily: 'Trebuchet MS',
              offsetY: 70,
            },
            value: {
              color: 'white',
              fontSize: '100px',
              fontFamily: 'Trebuchet MS',
              show: true,
              formatter: function (val) {
                return currentvalue + ``;
              },
            },
          },
        },
      },
      fill: {
        type: 'gradient',
        colors: ['#00ce76'],
      },
      labels: ['Body Mass Index(BMI)'],
    };
  }

}